import { asyncTypeFailure, asyncTypeRequest, asyncTypeSuccess } from '../types';

import { DEFAULT_PARAMS_SEARCH_CASE } from 'const';
import caseTypes, { RESET_CASES, UPDATE_CASE_THUMBNAIL } from '../types/case';

const initialState = {
  error: {
    cases: null,
    case: null,
    pinCases: null,
    updateCase: null
  },
  cases: {
    isLoading: true,
    data: []
  },
  pinCases: {
    isLoading: true,
    data: []
  },
  case: {
    isLoading: true,
    data: {}
  },
  paramsSearchCases: DEFAULT_PARAMS_SEARCH_CASE,
  paramsSearchCaseDetail: {}
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CASE_THUMBNAIL: {
      return {
        ...state,
        case: {
          ...(state?.case || {}),
          data: {
            ...(state?.case?.data || {}),
            thumbnail: action?.data || ''
          }
        }
      };
    }
    case RESET_CASES: {
      return {
        ...state,
        cases: {
          isLoading: action?.loading,
          data: []
        }
      };
    }
    case asyncTypeRequest(caseTypes.GET_CASES): {
      return {
        ...state,
        cases: {
          ...state.cases,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(caseTypes.GET_CASES): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        cases: {
          ...state.cases,
          data: isLoadmore
            ? [...state.cases.data, ...action?.response?.data]
            : action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          cases: null
        }
      };
    }

    case asyncTypeFailure(caseTypes.GET_CASES): {
      return {
        ...state,
        cases: {
          ...state.cases,
          isLoading: false
        },
        error: {
          ...state.error,
          cases: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(caseTypes.GET_CASE_DETAIL): {
      return {
        ...state,
        case: {
          ...state.case,
          data: {},
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(caseTypes.GET_CASE_DETAIL): {
      return {
        ...state,
        case: {
          ...state.case,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          case: null
        }
      };
    }

    case asyncTypeFailure(caseTypes.GET_CASE_DETAIL): {
      return {
        ...state,
        case: {
          ...state.case,
          isLoading: false
        },
        error: {
          ...state.error,
          case: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(caseTypes.GET_PIN_CASES): {
      return {
        ...state,
        pinCases: {
          ...state.pinCases,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(caseTypes.GET_PIN_CASES): {
      return {
        ...state,
        pinCases: {
          ...state.pinCases,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          cases: null
        }
      };
    }

    case asyncTypeFailure(caseTypes.GET_PIN_CASES): {
      return {
        ...state,
        pinCases: {
          ...state.pinCases,
          isLoading: false
        },
        error: {
          ...state.error,
          pinCases: action?.error?.message
        }
      };
    }

    case caseTypes.RESET_PIN_CASES: {
      return {
        ...state,
        pinCases: {
          ...state.pinCases,
          data: []
        }
      };
    }
    case asyncTypeSuccess(caseTypes.UPDATE_CASE_DETAIL): {
      return {
        ...state,
        case: {
          ...state.case,
          data: action?.response?.data
        },
        error: {
          ...state.error,
          updateCase: null
        }
      };
    }

    case asyncTypeFailure(caseTypes.UPDATE_CASE_DETAIL): {
      return {
        ...state,
        error: {
          ...state.error,
          updateCase: action?.error?.message
        }
      };
    }

    case caseTypes.UPDATE_PARAMS_SEARCH_CASES: {
      const { payload } = action;
      return {
        ...state,
        paramsSearchCases: { ...payload }
      };
    }

    case caseTypes.RESET_PARAMS_SEARCH_CASES: {
      return {
        ...state,
        paramsSearchCases: { ...DEFAULT_PARAMS_SEARCH_CASE }
      };
    }

    case caseTypes.UPDATE_PARAMS_SEARCH_CASES_DETAIL: {
      const { payload } = action;
      return {
        ...state,
        paramsSearchCaseDetail: { ...payload }
      };
    }

    case caseTypes.RESET_PARAMS_SEARCH_CASE_DETAIL: {
      return {
        ...state,
        paramsSearchCaseDetail: {}
      };
    }

    default:
      return state;
  }
};

export default userReducers;
