import appReducer from 'portals/business/store/reducers/app';
import businessReducer from 'portals/business/store/reducers/business';
import caseReducer from 'portals/business/store/reducers/case';
import { combineReducers } from 'redux';
import customerReducer from 'portals/business/store/reducers/customer';
import { persistReducer } from 'redux-persist';
import requestReducer from 'portals/business/store/reducers/request';
import storage from 'redux-persist/lib/storage'; // de
import userReducer from 'portals/business/store/reducers/user';

const persistConfig = {
  key: 'PT-user',
  storage,
  whitelist: ['token', 'myProfile', 'myBusiness'],
};

const persistAppConfig = {
  key: 'PT-app',
  storage,
  blacklist: ['modal'],
};

const rootReducer = combineReducers({
  app: persistReducer(persistAppConfig, appReducer),
  user: persistReducer(persistConfig, userReducer),
  cases: caseReducer,
  customers: customerReducer,
  businesses: businessReducer,
  requests: requestReducer
});

export default rootReducer;
