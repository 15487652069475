import {
  DEFAULT_PARAMS_SEARCH_BUSINESS,
  DEFAULT_PARAMS_SEARCH_MEMBERS
} from 'const';
import { asyncTypeFailure, asyncTypeRequest, asyncTypeSuccess } from '../types';

import businessTypes from '../types/business';
import userTypes from '../types/user';

const initialState = {
  error: {
    businesses: null,

    pinBusinesses: null
  },
  businesses: {
    isAll: false,
    isLoading: true,
    data: []
  },
  pinBusinesses: {
    isLoading: true,
    data: []
  },
  paramsSearchBusinesses: DEFAULT_PARAMS_SEARCH_BUSINESS,
  businessTypes: {
    data: [],
    isLoading: true
  },
  businessesGlobal: {
    isLoading: true,
    data: []
  },
  business: {
    data: {},
    isLoading: true
  },
  contactMember: {
    isAll: false,
    data: [],
    isLoading: true
  },
  members: {
    isLoading: true,
    data: []
  },
  pinMembers: {
    isLoading: true,
    data: []
  },
  paramsSearchMembers: DEFAULT_PARAMS_SEARCH_MEMBERS,
  member: {
    data: {},
    isLoading: true
  }
};

const businessReducers = (state = initialState, action) => {
  switch (action.type) {
    case asyncTypeRequest(businessTypes.GET_BUSINESSES): {
      return {
        ...state,
        businesses: {
          ...state.businesses,
          isAll: !!action?.payload?.isAll,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.GET_BUSINESSES): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        businesses: {
          ...state.businesses,
          data: isLoadmore
            ? [...state.businesses.data, ...action?.response?.data]
            : action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          businesses: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_BUSINESSES): {
      return {
        ...state,
        businesses: {
          ...state.businesses,
          isLoading: false
        },
        error: {
          ...state.error,
          businesses: action?.error?.message
        }
      };
    }
    case asyncTypeRequest(businessTypes.GET_PIN_BUSINESSES): {
      return {
        ...state,
        pinBusinesses: {
          ...state.pinBusinesses,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.GET_PIN_BUSINESSES): {
      return {
        ...state,
        pinBusinesses: {
          ...state.pinBusinesses,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          businesses: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_PIN_BUSINESSES): {
      return {
        ...state,
        pinBusinesses: {
          ...state.pinBusinesses,
          isLoading: false
        },
        error: {
          ...state.error,
          pinBusinesses: action?.error?.message
        }
      };
    }

    case businessTypes.RESET_PIN_CASES: {
      return {
        ...state,
        pinBusinesses: {
          ...state.pinBusinesses,
          data: []
        }
      };
    }
    case businessTypes.UPDATE_PARAMS_SEARCH_BUSINESSES: {
      const { payload } = action;
      return {
        ...state,
        paramsSearchBusinesses: { ...payload }
      };
    }

    case businessTypes.RESET_PARAMS_SEARCH_BUSINESSES: {
      return {
        ...state,
        paramsSearchBusinesses: { ...DEFAULT_PARAMS_SEARCH_BUSINESS }
      };
    }

    case asyncTypeRequest(businessTypes.GET_BUSINESS_TYPES): {
      return {
        ...state,
        businessTypes: {
          ...state.businessTypes,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.GET_BUSINESS_TYPES): {
      return {
        ...state,
        businessTypes: {
          ...state.businessTypes,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          businessTypes: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_BUSINESS_TYPES): {
      return {
        ...state,
        businessTypes: {
          ...state.businessTypes,
          isLoading: false
        },
        error: {
          ...state.error,
          businessTypes: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(businessTypes.GET_BUSINESSES_GLOBAL): {
      return {
        ...state,
        businessesGlobal: {
          ...state.businessesGlobal,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.GET_BUSINESSES_GLOBAL): {
      return {
        ...state,
        businessesGlobal: {
          ...state.businessesGlobal,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          businessesGlobal: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_BUSINESSES_GLOBAL): {
      return {
        ...state,
        businessesGlobal: {
          ...state.businessTypes,
          isLoading: false
        },
        error: {
          ...state.error,
          businessesGlobal: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(businessTypes.GET_BUSINESS_DETAIL): {
      return {
        ...state,
        business: {
          ...state.business,
          data: {},
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.GET_BUSINESS_DETAIL): {
      return {
        ...state,
        business: {
          ...state.business,
          data: action?.response?.data,
          isLoading: false
        }
      };
    }
    case asyncTypeRequest(businessTypes.UPDATE_BUSINESS_DETAIL): {
      return {
        ...state,
        business: {
          ...state.business,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.UPDATE_BUSINESS_DETAIL): {
      return {
        ...state,
        business: {
          ...state.business,
          data: action?.response?.data,
          isLoading: false
        }
      };
    }

    // get contact members
    case asyncTypeRequest(businessTypes.GET_CONTACT_MEMBERS): {
      return {
        ...state,
        contactMember: {
          ...state.contactMember,
          isAll: !!action?.payload?.isAll,
          isLoading: true
        }
      };
    }

    case asyncTypeSuccess(businessTypes.GET_CONTACT_MEMBERS): {
      const { isLoadmore } = action?.payload;

      return {
        ...state,
        contactMember: {
          ...state.contactMember,
          data: isLoadmore
            ? [...state.contactMember.data, ...action?.response?.data]
            : action?.response?.data,

          isLoading: false
        },
        error: {
          ...state.error,
          contactMember: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_CONTACT_MEMBERS): {
      return {
        ...state,
        contactMember: {
          ...state.businessTypes,
          isLoading: false
        },
        error: {
          ...state.error,
          contactMember: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(businessTypes.GET_MEMBERS): {
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: true
        }
      };
    }

    case asyncTypeSuccess(businessTypes.GET_MEMBERS): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        members: {
          ...state.members,
          data: isLoadmore
            ? [...state.members.data, ...action?.response?.data]
            : action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          members: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_MEMBERS): {
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: false
        },
        error: {
          ...state.error,
          members: action?.error?.message
        }
      };
    }

    case asyncTypeRequest(businessTypes.GET_PIN_MEMBERS): {
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: true
        }
      };
    }

    case asyncTypeSuccess(businessTypes.GET_PIN_MEMBERS): {
      return {
        ...state,
        members: {
          ...state.members,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          businesses: null
        }
      };
    }

    case asyncTypeFailure(businessTypes.GET_PIN_MEMBERS): {
      return {
        ...state,
        members: {
          ...state.members,
          isLoading: false
        },
        error: {
          ...state.error,
          members: action?.error?.message
        }
      };
    }

    case businessTypes.RESET_PIN_MEMBERS: {
      return {
        ...state,
        members: {
          ...state.members,
          data: []
        }
      };
    }
    case businessTypes.UPDATE_PARAMS_SEARCH_MEMBERS: {
      const { payload } = action;
      return {
        ...state,
        paramsSearchMembers: { ...payload }
      };
    }

    case businessTypes.RESET_PARAMS_SEARCH_MEMBERS: {
      return {
        ...state,
        paramsSearchMembers: { ...DEFAULT_PARAMS_SEARCH_MEMBERS }
      };
    }
    case asyncTypeSuccess(userTypes.USER_LOGOUT):
    case asyncTypeRequest(userTypes.USER_LOGOUT): {
      return {
        ...initialState
      };
    }
    case asyncTypeRequest(businessTypes.GET_MEMBER_DETAIL): {
      return {
        ...state,
        member: {
          ...state.member,
          data: {},
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.GET_MEMBER_DETAIL): {
      return {
        ...state,
        member: {
          ...state.member,
          data: action?.response?.data,
          isLoading: false
        }
      };
    }

    case asyncTypeRequest(businessTypes.UPDATE_MEMBER_DETAIL): {
      return {
        ...state,
        member: {
          ...state.member,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(businessTypes.UPDATE_MEMBER_DETAIL): {
      return {
        ...state,
        member: {
          ...state.member,
          data: action?.response?.data,
          isLoading: false
        }
      };
    }
    default:
      return state;
  }
};

export default businessReducers;
